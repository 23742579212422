<div class="container">
  <table mat-table #table [dataSource]="dataSource" matSort matSortActive="period" matSortDirection="asc">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Nom
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="period">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Période
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.period }}
      </td>
    </ng-container>

    <ng-container matColumnDef="time">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Horaires
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.time | courseTime }}
      </td>
    </ng-container>

    <ng-container matColumnDef="ECTS">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        ECTS
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.ECTS }}
      </td>
    </ng-container>

    <ng-container matColumnDef="master">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Type
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.master ? element.master.shortName : 'Cours IMI' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Lieu
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.shortLocation || element.location }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-actions">
      </th>
      <td mat-cell *matCellDef="let element" class="col-actions">
        <button mat-icon-button color="primary" (click)="remove(element)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<div fxLayout="row">
  <p [class]="getECTSSumClass()">
    {{ formatECTSSum() }}
  </p>
  <span fxFlex></span>
  <button mat-raised-button color="primary"
          *ngIf="!isDisabled"
          (click)="openCourseSelectionDialog()"
  >
    <mat-icon>add</mat-icon>
    Ajouter des cours
  </button>
</div>
