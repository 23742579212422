<h2 mat-dialog-title>
    {{ title }}
</h2>
<mat-dialog-content>
    {{ text }}
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Non</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Oui</button>
</mat-dialog-actions>
