import { OnInit } from '@angular/core';
import { MatDialog, MatTableDataSource, MatSort } from '@angular/material';
import { CourseSelectionDialogComponent } from './course-selection-dialog.component';
import { ControlValueAccessor } from '@angular/forms';
var CourseSelectionInputComponent = /** @class */ (function () {
    function CourseSelectionInputComponent(dialog) {
        this.dialog = dialog;
        this.isDisabled = false;
        this.onChange = function (newValue) { };
    }
    CourseSelectionInputComponent.prototype.ngOnInit = function () {
        this.dataSource = new MatTableDataSource([]);
        this.dataSource.sort = this.sort;
    };
    Object.defineProperty(CourseSelectionInputComponent.prototype, "displayedColumns", {
        get: function () {
            var baseColumns = ['name', 'master', 'period', 'time', 'location', 'ECTS'];
            if (this.isDisabled) {
                return baseColumns;
            }
            return baseColumns.concat([
                'actions',
            ]);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CourseSelectionInputComponent.prototype, "selectedCourses", {
        get: function () {
            return this.dataSource.data;
        },
        set: function (selectedCourses) {
            this.dataSource.data = selectedCourses;
            this.onChange(this.selectedCourses);
        },
        enumerable: true,
        configurable: true
    });
    CourseSelectionInputComponent.prototype.remove = function (element) {
        this.selectedCourses = this.selectedCourses.filter(function (x) { return x.id !== element.id; });
    };
    CourseSelectionInputComponent.prototype.openCourseSelectionDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(CourseSelectionDialogComponent, {
            width: '65vw',
            data: {
                availableCourses: this.availableCourses,
            },
        });
        dialogRef.afterClosed().subscribe(function (selectedCourses) {
            if (selectedCourses) {
                _this.selectedCourses = _this.selectedCourses.concat(selectedCourses);
            }
        });
    };
    CourseSelectionInputComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    CourseSelectionInputComponent.prototype.registerOnTouched = function (fn) {
    };
    CourseSelectionInputComponent.prototype.setDisabledState = function (isDisabled) {
        this.isDisabled = isDisabled;
    };
    CourseSelectionInputComponent.prototype.writeValue = function (obj) {
        this.dataSource.data = obj;
    };
    CourseSelectionInputComponent.prototype.formatECTSSum = function () {
        switch (this.mode) {
            case '3A':
                return "Total d'ECTS pour le master : " + this.computeECTSSum();
            case 'option2':
                return "Total d'ECTS pour l'option 2 : " + this.computeECTSSum() + " / 15";
            default:
                return '';
        }
    };
    CourseSelectionInputComponent.prototype.getECTSSumClass = function () {
        if (this.mode === 'option2' && this.computeECTSSum() < 15) {
            return 'ECTS-wrapper ECTS-error';
        }
        return 'ECTS-wrapper';
    };
    CourseSelectionInputComponent.prototype.computeECTSSum = function () {
        return this.selectedCourses.reduce(function (agg, item) { return agg + item.ECTS; }, 0);
    };
    return CourseSelectionInputComponent;
}());
export { CourseSelectionInputComponent };
