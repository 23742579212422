<h2>
  Mon parcours 3A
</h2>


<div *ngIf="!parcours">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="parcours">
  <mat-vertical-stepper linear>
    <ng-template matStepperIcon="edit">
      <mat-icon>check</mat-icon>
    </ng-template>

    <!-- Step 1 -->

    <mat-step label="Étape 1 : Choix du master" completed>
      <h3>
        Tu as choisi ton master
      </h3>

      <p>
        Ton master est : {{ parcours.master.name }}
      </p>
    </mat-step>

    <!-- Step 2 -->

    <mat-step label="Étape 2 : Choix de l'option" [completed]="hasOption">
      <div *ngIf="!hasOption">
        <h3>
          Tu n'as pas encore choisi ton option
        </h3>

        <app-option-description [options]="availableOptions"></app-option-description>

        <form>
          <mat-form-field>
            <mat-select placeholder="Formule 3A" required name="selectedOption" [(ngModel)]="selectedOption">
              <mat-option *ngFor="let option of availableOptions" [value]="option">
                {{ option.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>

        <button mat-raised-button color="warn" (click)="submitOption()">
          <mat-icon>send</mat-icon>
          Valider mon option (choix définitif)
        </button>
      </div>

      <div *ngIf="hasOption">
        <h3>
          Tu as choisi ton option
        </h3>

        <app-option-description [options]="availableOptions"></app-option-description>

        <br/>

        <p>
          Ton option est : <strong>{{ displayOption }}</strong>
        </p>
      </div>
    </mat-step>

    <!-- Step 3 -->

    <mat-step label="Étape 3 : Choix des cours" [completed]="hasSubmittedCourses">
      <div *ngIf="hasNoCourses || hasPendingCourses">
        <h3 *ngIf="hasNoCourses">
          Tu n'as pas encore renseigné tes choix de cours de 3A
        </h3>

        <h3 *ngIf="hasPendingCourses">
          Tu n'as pas encore envoyé tes choix de cours de 3A
        </h3>

        <app-courses-description></app-courses-description>

        <button mat-raised-button color="primary" routerLink="/parcours/edit">
          <mat-icon>save</mat-icon>
          Renseigner mes choix de cours
        </button>
      </div>

      <div *ngIf="hasSubmittedCourses">
        <h3>
          Tu as bien envoyé tes choix de cours de 3A
        </h3>

        <app-courses-description></app-courses-description>

        <button mat-raised-button color="primary" routerLink="/parcours/show">
          <mat-icon>remove_red_eye</mat-icon>
          Consulter mes choix de cours
        </button>
      </div>
    </mat-step>

  </mat-vertical-stepper>
</div>
