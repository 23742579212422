import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from './confirm/confirm-dialog.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var DialogService = /** @class */ (function () {
    function DialogService(dialog) {
        this.dialog = dialog;
    }
    DialogService.prototype.confirm = function (title, text) {
        var dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '40vw',
            data: {
                title: title,
                text: text,
            },
        });
        return dialogRef.afterClosed();
    };
    DialogService.ngInjectableDef = i0.defineInjectable({ factory: function DialogService_Factory() { return new DialogService(i0.inject(i1.MatDialog)); }, token: DialogService, providedIn: "root" });
    return DialogService;
}());
export { DialogService };
