<p class="master-links">
  <a href="http://math.ens-paris-saclay.fr/version-francaise/formations/master-mva/contenus-/master-mva-cours-2017-2018-161721.kjsp?RH=1242430202531">
    Descriptif des cours du MVA
  </a>

  <span class="sep">|</span>

  <a href="https://uma.ensta-paristech.fr/mpro/master2">
    Descriptif des cours du MPRO
  </a>
<p>

<p>
  Informations auprès du département IMI pour les cours hors master.
</p>

<p>
  Si tu choisis un projet de recherche, il faut d'abord le faire approuver par le département, puis remplir le champ de texte libre pour décrire le projet.
</p>

<p>
  Tu peux commencer à remplir le formulaire et sauvegarder tes choix au fur et à mesure.
</p>

<p>
  Pense à répartir au mieux ta charge de travail.
</p>

<p>
  <strong>Attention :</strong> Le choix des cours qui valident ton master et ceux qui valident tes 15 ECTS supplémentaires auprès des Ponts est arbitraire. Ils pourront être échangés sur le relevé de note. Cela n'a pas d'impact sur ton cursus, il faut juste veiller à avoir le bon total.
</p>

<p>
  <strong>Attention :</strong> Dans le cadre d'une <strong>3A école</strong>, les cours dans les masters recherche doivent être approuvés par le département IMI et le responsable du Master concerné. Le parcours doit être validé par le département IMI.
</p>
