import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ParcoursService = /** @class */ (function () {
    function ParcoursService(http) {
        this.http = http;
    }
    ParcoursService.prototype.get = function () {
        return this.http.get('/users/me/parcours');
    };
    ParcoursService.prototype.updateOption = function (option) {
        return this.http.put('/users/me/parcours_option', {
            option: option.id,
        });
    };
    ParcoursService.prototype.updateCourseChoice = function (courseChoice) {
        return this.http.put('/users/me/parcours_courses', courseChoice);
    };
    ParcoursService.prototype.checkCourseChoice = function (courseChoice) {
        return this.http.put('/users/me/parcours_courses_check', courseChoice);
    };
    ParcoursService.ngInjectableDef = i0.defineInjectable({ factory: function ParcoursService_Factory() { return new ParcoursService(i0.inject(i1.HttpClient)); }, token: ParcoursService, providedIn: "root" });
    return ParcoursService;
}());
export { ParcoursService };
