import { Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'environments/environment';
import { catchError } from 'rxjs/operators';
import * as Cookies from 'js-cookie';
var AuthInterceptor = /** @class */ (function () {
    function AuthInterceptor(injector) {
        this.injector = injector;
    }
    Object.defineProperty(AuthInterceptor.prototype, "authService", {
        get: function () {
            return this.injector.get(AuthService);
        },
        enumerable: true,
        configurable: true
    });
    AuthInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        var baseUrl = environment.baseUrl;
        // For backend API
        if (req.url.startsWith('/')) {
            req = req.clone({
                url: baseUrl + req.url,
            });
            var csrfToken = Cookies.get('csrftoken');
            req = req.clone({
                headers: req.headers.set('X-CSRFToken', csrfToken)
            });
        }
        return next.handle(req).pipe(catchError(function (err) {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401 || err.status === 403) {
                    _this.authService.logout();
                }
            }
            return throwError(err);
        }));
    };
    return AuthInterceptor;
}());
export { AuthInterceptor };
