import { LayoutComponent } from '../components/layout/layout.component';
import { LoginComponent } from '../components/login/login.component';
import { ParcoursIntroComponent } from '../components/parcours-intro/parcours-intro.component';
import { ParcoursCoursesFormComponent } from '../components/parcours-courses-form/parcours-courses-form.component';
import { AuthGuard } from '../services/auth.guard';
import { ParcoursResolver } from './parcours.resolver';
import { CoursesResolver } from './courses.resolver';
var ɵ0 = {
    view: 'show',
}, ɵ1 = {
    view: 'edit',
};
var routes = [
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: '',
        component: LayoutComponent,
        canActivate: [
            AuthGuard,
        ],
        children: [
            {
                path: '',
                redirectTo: 'parcours',
                pathMatch: 'full',
            },
            {
                path: 'parcours',
                children: [
                    {
                        path: '',
                        component: ParcoursIntroComponent,
                    },
                    {
                        path: '',
                        resolve: {
                            parcours: ParcoursResolver,
                            courses: CoursesResolver,
                        },
                        children: [
                            {
                                path: 'show',
                                component: ParcoursCoursesFormComponent,
                                data: ɵ0,
                            },
                            {
                                path: 'edit',
                                component: ParcoursCoursesFormComponent,
                                data: ɵ1,
                            },
                        ]
                    },
                ],
            },
        ],
    },
];
var RoutingModule = /** @class */ (function () {
    function RoutingModule() {
    }
    return RoutingModule;
}());
export { RoutingModule };
export { ɵ0, ɵ1 };
