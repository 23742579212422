<mat-toolbar color="primary">
  <button mat-icon-button color="white" class="sidenav-button" (click)="toggleSidenav()">
    <mat-icon>menu</mat-icon>
  </button>
  <button mat-button color="white" class="title-button" routerLink="/">
    Ma 3A
  </button>

  <span class="fill-remaining-space"></span>

  <span class="username">
    Connecté en tant que {{ currentUser.username }}
  </span>
  <!-- <button mat-raised-button (click)="logout()">
    Se déconnecter
  </button> -->
</mat-toolbar>




<mat-sidenav-container>
  <mat-sidenav mode="side" [opened]="sideNavOpened">
    <mat-nav-list>
      <!-- <a mat-list-item routerLink="/" routerLinkActive>
        <mat-icon>home</mat-icon>
        Accueil
      </a> -->
      <a mat-list-item routerLink="/parcours" routerLinkActive>
        <mat-icon>assignment</mat-icon>
        Mon parcours 3A
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="layout-container">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

