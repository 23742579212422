<article class="option-description" *ngIf="inOptions('3A-ecole')">
  <h4>
    3A École
  </h4>

  <p>
    Pas de double diplôme. L'élève doit valider 60 ECTS sur l'année :
  </p>

  <ul>
    <li>
      25.5 ECTS scientifiques (parmi les cours d'un master recherche + les cours tels que Maths for Business, Data Driven Optimisation
      et Algorithme de Monte Carlo par chaine de Markov)
    </li>
    <li>
      4.5 ECTS de langue (i.e. finir ses obligations de langue pour l'ENPC -> les masters en France n'impactent pas les obligations
      de langue)
    </li>
    <li>
      30 ECTS de PFE
    </li>
  </ul>
</article>

<article class="option-description" *ngIf="inOptions('3A-M2-PFE')">
  <h4>
    3A M2 Imbriqués - Avec prolongation de scolarité
  </h4>

  <p>
    Obtention du Master, puis PFE des Ponts.
  </p>

  <ul>
    <li>
      L'élève doit valider les obligations de son master recherche normalement (par exemple 8 cours du MVA) (dont le stage de recherche
      à la fin)
    </li>
    <li>
      4.5 ECTS de langue (i.e. finir ses obligations de langue pour l'ENPC -> les masters en France n'impactent pas les obligations
      de langue)
    </li>
    <li>
      30 ECTS de PFE (ou début de thèse)
    </li>
  </ul>
</article>

<article class="option-description" *ngIf="inOptions('3A-M2-ECTS')">
  <h4>
    3A M2 Imbriqués - Avec ECTS supplémentaires
  </h4>

  <p>
    Obtention du Master, et validation de 15 ECTS supplémentaires à la place du PFE des Ponts.
  </p>

  <ul>
    <li>
      L'élève doit valider les obligations de son master recherche normalement (par exemple 8 cours du MVA) (dont le stage de recherche
      à la fin)
    </li>
    <li>
      4.5 ECTS de langue (i.e. finir ses obligations de langue pour l'ENPC -> les masters en France n'impactent pas les obligations
      de langue)
    </li>
    <li>
      15 ECTS scientifiques 
    </li>
  </ul>
</article>
