<div class="login-form-wrapper">
  <div class="login-form">
    <h1>
      Ma 3A
    </h1>

    <mat-card>
      <mat-card-header>
        <mat-card-title>
          <h3>
            Redirection vers le CAS...
          </h3>
        </mat-card-title>
      </mat-card-header>
    </mat-card>
  </div>
</div>
