<h2>
  Mon parcours 3A
</h2>

<mat-card>
  <mat-card-title>
    Formulaire de validation des choix d'options de 3A
  </mat-card-title>
  <mat-card-content>
    <app-courses-description></app-courses-description>

    <form [formGroup]="form">
      <section>
        <h3>
          Choix des cours pour la validation du {{ parcours.master.name }}
        </h3>

        <app-course-selection-input
          mode="3A"
          formControlName="mainCourses"
          [availableCourses]="availableMasterCourses"
        >
        </app-course-selection-input>
      </section>

      <section *ngIf="parcours.option.id === '3A-M2-ECTS'">
        <h3>
          Choix des cours supplémentaires pour la validation de la {{ parcours.option.name }}
        </h3>

        <app-course-selection-input
          mode="option2"
          formControlName="optionCourses"
          [availableCourses]="availableCourses"
        >
        </app-course-selection-input>
      </section>

      <section>
        <h3>
          Règles de validation
        </h3>

        <p *ngFor="let rule of checkParcoursRules" [ngClass]="{'warning-rule': rule.type == 'warning', 'error-rule': rule.type == 'error'}">
          <mat-icon>{{ ruleTypeToIcon(rule.type) }}</mat-icon> {{ rule.message }}
        </p>
      </section>

      <section>
        <mat-form-field>
          <textarea matInput matTextareaAutosize matAutosizeMinRows="3"
                    formControlName="comment" placeholder="Commentaires éventuels..."
          >
          </textarea>
        </mat-form-field>
      </section>
    </form>
  </mat-card-content>
  <mat-card-actions fxLayout="row" *ngIf="!disabled">
    <button mat-raised-button color="primary" fxFlex (click)="save()">
      <mat-icon>save</mat-icon>
      Sauvegarder
    </button>
    <button mat-raised-button color="accent" fxFlex (click)="submit()">
      <mat-icon>send</mat-icon>
      Envoyer définitivement
    </button>
  </mat-card-actions>
</mat-card>
