import { Router } from '@angular/router';
import { UserService } from './user.service';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./user.service";
var AuthService = /** @class */ (function () {
    function AuthService(router, userService) {
        this.router = router;
        this.userService = userService;
        this.currentUser = new BehaviorSubject(null);
    }
    AuthService.prototype.casLogin = function () {
        var _this = this;
        this.userService.getCurrentUser().subscribe(function (currentUser) {
            _this.currentUser.next(currentUser);
            _this.router.navigateByUrl('/');
        }, function (error) {
            window.location.href = environment.casUrl;
        });
    };
    AuthService.prototype.logout = function () {
        this.cleanStorage();
        this.router.navigateByUrl('/login');
    };
    AuthService.prototype.reloadCurrentUser = function () {
        var _this = this;
        this.userService.getCurrentUser().subscribe(function (currentUser) {
            _this.currentUser.next(currentUser);
        });
    };
    AuthService.prototype.cleanStorage = function () {
        this.currentUser.next(null);
    };
    AuthService.prototype.isLoggedIn = function () {
        return this.currentUser.value !== null;
    };
    AuthService.prototype.getCurrentUser = function () {
        return this.currentUser.asObservable();
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.Router), i0.inject(i2.UserService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
