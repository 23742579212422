import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ParcoursService } from 'app/services/parcours.service';
import { AuthService } from 'app/services/auth.service';
import { DialogService } from 'app/services/dialog/dialog.service';
var ParcoursCoursesFormComponent = /** @class */ (function () {
    function ParcoursCoursesFormComponent(route, router, fb, authService, dialogService, parcoursService) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.fb = fb;
        this.authService = authService;
        this.dialogService = dialogService;
        this.parcoursService = parcoursService;
        this.disabled = false;
        this.checkParcoursRules = [];
        this.checkParcours = function (form) {
            var courseChoice = buildCourseChoice(form, false);
            _this.parcoursService.checkCourseChoice(courseChoice).subscribe(function (res) {
                _this.checkParcoursRules = res;
            });
        };
    }
    ParcoursCoursesFormComponent.prototype.ngOnInit = function () {
        this.disabled = this.route.snapshot.data.view === 'show';
        this.courses = this.route.snapshot.data.courses;
        this.parcours = this.route.snapshot.data.parcours;
        var courseChoice = this.route.snapshot.data.parcours.courseChoice || {
            mainCourses: [],
            optionCourses: [],
        };
        this.form = this.fb.group({
            mainCourses: [this.coursesIdToCourses(courseChoice.mainCourses)],
            optionCourses: [this.coursesIdToCourses(courseChoice.optionCourses)],
            comment: [courseChoice.comment],
        });
        this.form.valueChanges.subscribe(this.checkParcours);
        this.checkParcours(this.form.value);
        if (this.disabled) {
            this.form.disable();
        }
    };
    Object.defineProperty(ParcoursCoursesFormComponent.prototype, "availableCourses", {
        get: function () {
            var selectedCourses = this.form.get('mainCourses').value.concat(this.form.get('optionCourses').value);
            return this.courses.filter(function (course) {
                return selectedCourses.find(function (selectedCourse) { return selectedCourse.id === course.id; }) === undefined;
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ParcoursCoursesFormComponent.prototype, "availableMasterCourses", {
        get: function () {
            var _this = this;
            if (this.parcours.option.id == '3A-ecole') {
                return this.availableCourses;
            }
            return this.availableCourses.filter(function (course) { return course.masters.includes(_this.parcours.master.id); });
        },
        enumerable: true,
        configurable: true
    });
    ParcoursCoursesFormComponent.prototype.save = function () {
        this.performUpdate(false);
    };
    ParcoursCoursesFormComponent.prototype.submit = function () {
        var _this = this;
        this.dialogService.confirm('Confirmation', 'Es-tu certain de vouloir envoyer tes choix de cours de 3A ?').subscribe(function (confirmed) {
            if (!confirmed) {
                return;
            }
            _this.performUpdate(true);
        });
    };
    ParcoursCoursesFormComponent.prototype.performUpdate = function (isSubmitted) {
        var _this = this;
        this.markAsTouched();
        var courseChoice = buildCourseChoice(this.form.value, isSubmitted);
        this.parcoursService.updateCourseChoice(courseChoice).subscribe(function () {
            _this.authService.reloadCurrentUser();
            _this.router.navigateByUrl('/parcours');
        });
    };
    ParcoursCoursesFormComponent.prototype.coursesIdToCourses = function (coursesId) {
        return this.courses.filter(function (course) { return coursesId.includes(course.id); });
    };
    ParcoursCoursesFormComponent.prototype.ruleTypeToIcon = function (ruleType) {
        if (ruleType == 'error') {
            return 'close';
        }
        if (ruleType == 'warning') {
            return 'warning';
        }
        if (ruleType == 'valid') {
            return 'check';
        }
        return '';
    };
    ParcoursCoursesFormComponent.prototype.markAsTouched = function () {
        Object.values(this.form.controls).forEach(function (control) { return control.markAsTouched(); });
    };
    return ParcoursCoursesFormComponent;
}());
export { ParcoursCoursesFormComponent };
function buildCourseChoice(formValue, isSubmitted) {
    var mainCourses = formValue.mainCourses, optionCourses = formValue.optionCourses, comment = formValue.comment;
    var courseChoice = {
        mainCourses: mainCourses.map(function (course) { return course.id; }),
        optionCourses: optionCourses.map(function (course) { return course.id; }),
        comment: comment,
        submitted: isSubmitted,
    };
    return courseChoice;
}
