/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./option-description.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./option-description.component";
var styles_OptionDescriptionComponent = [i0.styles];
var RenderType_OptionDescriptionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OptionDescriptionComponent, data: {} });
export { RenderType_OptionDescriptionComponent as RenderType_OptionDescriptionComponent };
function View_OptionDescriptionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "article", [["class", "option-description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 3A \u00C9cole "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Pas de double dipl\u00F4me. L'\u00E9l\u00E8ve doit valider 60 ECTS sur l'ann\u00E9e : "])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 25.5 ECTS scientifiques (parmi les cours d'un master recherche + les cours tels que Maths for Business, Data Driven Optimisation et Algorithme de Monte Carlo par chaine de Markov) "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 4.5 ECTS de langue (i.e. finir ses obligations de langue pour l'ENPC -> les masters en France n'impactent pas les obligations de langue) "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 30 ECTS de PFE "]))], null, null); }
function View_OptionDescriptionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "article", [["class", "option-description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 3A M2 Imbriqu\u00E9s - Avec prolongation de scolarit\u00E9 "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Obtention du Master, puis PFE des Ponts. "])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" L'\u00E9l\u00E8ve doit valider les obligations de son master recherche normalement (par exemple 8 cours du MVA) (dont le stage de recherche \u00E0 la fin) "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 4.5 ECTS de langue (i.e. finir ses obligations de langue pour l'ENPC -> les masters en France n'impactent pas les obligations de langue) "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 30 ECTS de PFE (ou d\u00E9but de th\u00E8se) "]))], null, null); }
function View_OptionDescriptionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "article", [["class", "option-description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 3A M2 Imbriqu\u00E9s - Avec ECTS suppl\u00E9mentaires "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Obtention du Master, et validation de 15 ECTS suppl\u00E9mentaires \u00E0 la place du PFE des Ponts. "])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" L'\u00E9l\u00E8ve doit valider les obligations de son master recherche normalement (par exemple 8 cours du MVA) (dont le stage de recherche \u00E0 la fin) "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 4.5 ECTS de langue (i.e. finir ses obligations de langue pour l'ENPC -> les masters en France n'impactent pas les obligations de langue) "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 15 ECTS scientifiques "]))], null, null); }
export function View_OptionDescriptionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_OptionDescriptionComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OptionDescriptionComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OptionDescriptionComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inOptions("3A-ecole"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.inOptions("3A-M2-PFE"); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.inOptions("3A-M2-ECTS"); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_OptionDescriptionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-option-description", [], null, null, null, View_OptionDescriptionComponent_0, RenderType_OptionDescriptionComponent)), i1.ɵdid(1, 49152, null, 0, i3.OptionDescriptionComponent, [], null, null)], null, null); }
var OptionDescriptionComponentNgFactory = i1.ɵccf("app-option-description", i3.OptionDescriptionComponent, View_OptionDescriptionComponent_Host_0, { options: "options" }, {}, []);
export { OptionDescriptionComponentNgFactory as OptionDescriptionComponentNgFactory };
