import { OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ParcoursService } from '../../services/parcours.service';
import { DialogService } from '../../services/dialog/dialog.service';
var ParcoursIntroComponent = /** @class */ (function () {
    function ParcoursIntroComponent(authService, dialogService, parcoursService) {
        this.authService = authService;
        this.dialogService = dialogService;
        this.parcoursService = parcoursService;
    }
    ParcoursIntroComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authService.getCurrentUser().subscribe(function (currentUser) {
            _this.parcours = currentUser.parcours;
            if (_this.parcours && _this.parcours.master) {
                _this.availableOptions = _this.parcours.master.availableOptions;
            }
        });
    };
    Object.defineProperty(ParcoursIntroComponent.prototype, "hasOption", {
        get: function () {
            return !!this.parcours.option;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ParcoursIntroComponent.prototype, "displayOption", {
        get: function () {
            return this.parcours.option.name;
        },
        enumerable: true,
        configurable: true
    });
    ParcoursIntroComponent.prototype.submitOption = function () {
        var _this = this;
        if (!this.selectedOption) {
            return;
        }
        this.dialogService.confirm('Confirmation', "Es-tu certain de vouloir choisir l'option " + this.selectedOption.name + " ?").subscribe(function (confirmed) {
            if (!confirmed) {
                return;
            }
            _this.parcoursService.updateOption(_this.selectedOption).subscribe(function () {
                _this.authService.reloadCurrentUser();
            });
        });
    };
    Object.defineProperty(ParcoursIntroComponent.prototype, "hasNoCourses", {
        get: function () {
            return !this.parcours.courseChoice;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ParcoursIntroComponent.prototype, "hasPendingCourses", {
        get: function () {
            return !!this.parcours.courseChoice && !this.parcours.courseChoice.submitted;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ParcoursIntroComponent.prototype, "hasSubmittedCourses", {
        get: function () {
            return !!this.parcours.courseChoice && this.parcours.courseChoice.submitted;
        },
        enumerable: true,
        configurable: true
    });
    return ParcoursIntroComponent;
}());
export { ParcoursIntroComponent };
