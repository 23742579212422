import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CourseService = /** @class */ (function () {
    function CourseService(http) {
        this.http = http;
    }
    CourseService.prototype.getAll = function () {
        return this.http.get('/courses');
    };
    CourseService.ngInjectableDef = i0.defineInjectable({ factory: function CourseService_Factory() { return new CourseService(i0.inject(i1.HttpClient)); }, token: CourseService, providedIn: "root" });
    return CourseService;
}());
export { CourseService };
